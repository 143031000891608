import { useDispatch, useSelector } from "react-redux";
import { selectCustomer, setSubscriptionDetail } from "../store/customerSlice";
import { useCallback, useEffect, useState } from "react";
import { getSubscriptions } from "../api/customer.api";

  
export const useFetchSubscriptions = () => {
  const dispatch = useDispatch();
  const customer = useSelector(selectCustomer);
  const [subscriptions, setSubscriptions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSubscriptions = useCallback(async () => {
    if (!customer?.id) return;
    setIsLoading(true);
    setError(null);
    try {
      const response = await getSubscriptions(customer.id);
      if (response?.message?.toLowerCase() === "success") {
        const subscription = response.data?.subscription?.[0] || null;

        setSubscriptions(subscription);
        if (subscription) dispatch(setSubscriptionDetail(subscription));
      } else {
        throw new Error("Failed to fetch subscriptions");
      }
    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  }, [customer?.id, dispatch]);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return { subscriptions, isLoading, error };
};