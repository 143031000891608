import React from 'react';
import AppointmentWithContactInfoSection from '../Section/AppointmentWithContactInfoSection';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';

export default function Appointments() {
  pageTitle('Appointments');
  return (
    <>
      {/* <BannerSectionStyle3
        bgUrl="/images/appointments/banner_bg.svg"
        imgUrl="/images/appointments/banner_img.png"
        title="Don’t Let Your Health Take a Backseat!"
        subTitle="Fill out the appointment form below to schedule a consultation with one of our healthcare professionals."
      /> */}
      <section
        className="cs_banner cs_style_3 cs_bg_filed"
        style={{ backgroundImage: `url(/images/appointments/banner_bg.svg)` }}
      >
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">
              Don’t Let Your Health Take a Backseat!
            </h2>
            <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
              Fill out the appointment form below to schedule a consultation
              with one of our healthcare professionals.
            </p>
          </div>
        </div>
      </section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AppointmentWithContactInfoSection />
      </Section>
    </>
  );
}
