import { Icon } from "@iconify/react";
import React, { useState } from "react";

export default function Team({
  imgUrl,
  name,
  designation,
  description,
  social,
}) {

  const [isExpanded, setIsExpanded] = useState(false);
  const maxChars = 200;

  return (
    <div className="cs_team cs_style_1 text-center cs_radius_20 cs_type_1">
      <div className="cs_member_img">
        <img
          src={imgUrl}
          alt={name}
          style={{ height: 250, width: 199, borderRadius: "30px" }}
        />
      </div>
      <div className="cs_team_meta">
        <div>
          <h3 className="cs_member_name cs_semibold cs_fs_40">{name}</h3>
          <p className="cs_member_designation cs_fs_20 cs_heading_color">
            {designation}
          </p>
          <p className="cs_member_description">
            {isExpanded ? description : `${description.slice(0, maxChars)}...`}
            {description.length > maxChars && (
              <span
                onClick={() => setIsExpanded(!isExpanded)}
                style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
              >
                {isExpanded ? " Show Less" : " Read More"}
              </span>
            )}
          </p>
        </div>
        <div>
          <div className="cs_social_links">
            {social?.map((item, index) => (
              <a
                href={item.href}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon={item.icon} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// using