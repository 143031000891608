import React, { useState } from "react";
import DashboardLayout from "../../ui/desktop/DashboardLayout";
import { Button } from "../../ui/input/Button";
import { useNavigate } from "react-router-dom";
import Card from "../../ui/common/Card";
import { Minus, X } from "lucide-react";
import {
  monthlyplans1,
  monthlyplans2,
  yearlyplans1,
  yearlyplans2,
} from "../../../utils/subscribtionPlans";
import { getRazorpayPaymentDetails } from "../../../api/customer.api";
import { useFetchSubscriptions } from "../../../hooks/useFetchSubscriptions";
import moment from "moment/moment";
import { ProgressBar } from "react-loader-spinner";

const Subscriptions = () => {
  const navigate = useNavigate();
  const { subscriptions, isLoading } = useFetchSubscriptions();
  const [paymentDetails, setPaymentDetails] = useState();
  const [seePaymentDetails, setSeePaymentDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPaymentDetails = async () => {
    setLoading(true);
    try {
      const response = await getRazorpayPaymentDetails(
        subscriptions.pg_payment_id
      );

      if (response.message.toLowerCase() === "success") {
        console.log(response.data.payment);
        setPaymentDetails(response.data.payment);
      }
    } catch (error) {
      console.error("Failed to fetch Payment Details", error);
    } finally {
      setLoading(false);
    }
  };

  const showPaymentDetails = () => {
    if (!paymentDetails) {
      fetchPaymentDetails();
    }
    setSeePaymentDetails(true);
  };

  return (
    <DashboardLayout>
      {isLoading ? (
        <div className="w-full h-[70vh] flex flex-col gap-1 items-center justify-center">
          <ProgressBar
            visible={true}
            height="150"
            width="150"
            color="#274760"
            barColor="#307bc4"
            borderColor="#274760"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          {subscriptions?.active ? (
            <>
              <div className="my-16">
                <div className="py-8 px-4">
                  <h2>My Current Plan</h2>
                  <div className="shadow-inner shadow-[#bcd8f3] lg:p-3 rounded-lg py-3 lg:flex lg:justify-between lg:items-center flex-wrap">
                    <Card>
                      <h4 className="text-center mb-2">
                        {subscriptions.duration === 3 ? (
                          <span>3 Months</span>
                        ) : (
                          <span>12 Months</span>
                        )}
                      </h4>
                      <h4 className="text-center mb-2">
                        {subscriptions.service === "type1"
                          ? "Health Check-Up"
                          : "Health Check-Up with Wellness Assurance"}
                      </h4>
                      <div>
                        <h4 className=" mb-2 text-center">
                          Number of Member: {subscriptions.member}
                        </h4>
                        <h4 className="text-xl text-center">
                          Amount Paid Rs. {subscriptions.amount}/-
                        </h4>
                        <ul className="list-none">
                          <li className="capitalize text-lg flex gap-2">
                            <Minus /> monthly wellness assessment
                          </li>
                          <li className="capitalize text-lg flex gap-2">
                            <Minus /> BP check
                          </li>
                          <li className="capitalize text-lg flex gap-2">
                            <Minus />
                            RBS
                          </li>
                          <li className="capitalize text-lg flex gap-2">
                            <Minus /> counselling
                          </li>
                          {subscriptions.service === "type2" && (
                            <li className="capitalize text-lg flex gap-2">
                              <Minus />
                              Wellness Products with 15 days moneyback guaranty.
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="flex justify-center my-3">
                        <button
                          className="text-[#307bc4] text-sm"
                          onClick={showPaymentDetails}
                        >
                          {loading ? "Loading..." : "See Payment Details"}
                        </button>
                      </div>
                      <div className="w-full flex justify-center">
                        <Button
                          className="w-[80%]"
                          variant="outline"
                          onClick={() => navigate("/paid-session")}
                        >
                          Renew
                        </Button>
                      </div>
                    </Card>

                    {seePaymentDetails &&
                      (paymentDetails ? (
                        <div className="p-1 border-1 border-slate-400 bg-slate-100 lg:mr-[10rem]">
                          <div className="h-[28px] flex justify-end items-center">
                            <X
                              size={24}
                              onClick={() => setSeePaymentDetails(false)}
                              className="hover:cursor-pointer hover:size-[28px] text-[#307bc4] font-bold"
                            />
                          </div>
                          <div className="px-3">
                            <h4 className="text-center font-normal">
                              Payment Details
                            </h4>
                            <div>
                              <p className="text-lg text-[#274760]">
                                Contact Number: {paymentDetails.contact}
                              </p>
                              <p className="text-lg text-[#274760]">
                                Payment ID: {paymentDetails.id}
                              </p>
                              <p className="text-lg text-[#274760]">
                                Subscribed on:{" "}
                                {moment(subscriptions.created_at).format(
                                  "MMM Do YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="p-4">
                          <h4 className="text-center">
                            No Payment Data Available at the moment!
                          </h4>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="mt-8 px-4">
                  <h2>Other Available Plans</h2>
                  <div className="mb-12 shadow-inner shadow-[#bcd8f3] lg:p-3 rounded-lg py-3">
                    <h4 className="text-center">Paid Health Check-Up</h4>
                    <div className="flex items-end justify-evenly flex-wrap gap-3">
                      <Card>
                        <h4 className="text-center">3 Months Plans</h4>
                        <ul>
                          {monthlyplans1.map((plan, index) => (
                            <li
                              key={index}
                              className="list-none flex items-center gap-2 mb-2"
                            >
                              <Minus />
                              <div>
                                <p className="mb-0 text-[#274760]">
                                  {plan.price}
                                </p>
                                <p className="text-sm">{plan.subtitle}</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div className="w-full flex justify-center">
                          <Button
                            className="w-[80%]"
                            variant="outline"
                            onClick={() => navigate("/paid-session")}
                          >
                            Update
                          </Button>
                        </div>
                      </Card>
                      <div className="border-1 border-[#307bc4] rounded-[1rem] bg-[#307bc4]">
                        <h5 className="text-center my-3 text-white">
                          Most Popular
                        </h5>
                        <Card>
                          <h4 className="text-center">Yearly Plans</h4>
                          <ul>
                            {yearlyplans1.map((plan, index) => (
                              <li
                                key={index}
                                className="list-none flex items-center gap-2 mb-2"
                              >
                                <Minus />
                                <div>
                                  <p className="mb-0 text-[#274760]">
                                    {plan.price}
                                  </p>
                                  <p className="text-sm">{plan.subtitle}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <div className="w-full flex justify-center">
                            <Button
                              className="w-[80%]"
                              onClick={() => navigate("/paid-session")}
                            >
                              Update
                            </Button>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>

                  <div className="my-8 shadow-inner shadow-[#bcd8f3] lg:p-3 rounded-lg py-3">
                    <h4 className="text-center">
                      Paid Health Check-Up with Wellness Assurance
                    </h4>
                    <div className="flex items-end justify-evenly flex-wrap gap-3">
                      <Card>
                        <h4 className="text-center">3 Months Plans</h4>
                        <ul>
                          {monthlyplans2.map((plan, index) => (
                            <li
                              key={index}
                              className="list-none flex items-center gap-2 mb-2"
                            >
                              <Minus />
                              <div>
                                <p className="mb-0 text-[#274760]">
                                  {plan.price}
                                </p>
                                <p className="text-sm">{plan.subtitle}</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div className="w-full flex justify-center">
                          <Button
                            className="w-[80%]"
                            variant="outline"
                            onClick={() => navigate("/paid-session")}
                          >
                            Update
                          </Button>
                        </div>
                      </Card>
                      <div className="border-1 border-[#307bc4] rounded-[1rem] bg-[#307bc4]">
                        <h5 className="text-center my-3 text-white">
                          Most Popular
                        </h5>
                        <Card>
                          <h4 className="text-center">Yearly Plans</h4>
                          <ul>
                            {yearlyplans2.map((plan, index) => (
                              <li
                                key={index}
                                className="list-none flex items-center gap-2 mb-2"
                              >
                                <Minus />
                                <div>
                                  <p className="mb-0 text-[#274760]">
                                    {plan.price}
                                  </p>
                                  <p className="text-sm">{plan.subtitle}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <div className="w-full flex justify-center">
                            <Button
                              className="w-[80%]"
                              onClick={() => navigate("/paid-session")}
                            >
                              Update
                            </Button>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="w-full h-[70vh] flex flex-col items-center justify-center text-center p-3">
              <h4>You have not subscribed to any plans yet.</h4>
              <h4>
                Book a Paid Health Checkup to see your Subscribed Plan Details
              </h4>
              <Button
                className=" text-lg font-semibold font-serif h-[5rem] w-fit"
                onClick={() => {
                  navigate("/paid-session");
                }}
              >
                Book a Paid Health Checkup
              </Button>
            </div>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default Subscriptions;
