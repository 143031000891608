import React from "react";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from ".";
import { Loader } from "lucide-react";

const SelectPaymentGateway = ({
  children,
  isPending,
  razorPay,
  payU,
}) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-[#e1f0ff]">
        <DialogHeader>
          <DialogTitle>Choose Your Payment Method</DialogTitle>
        </DialogHeader>
        <div className="flex justify-evenly space-y-1">
          <DialogClose>
            <p className="text-lg font-semibold text-[#274760]">Pay using Razorpay</p>
            <button
              className="cs_btn cs_style_1"
              onClick={razorPay}
              disabled={isPending}
            >
              <span>{isPending ? <Loader/> : "Pay Now"}</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
          </DialogClose>
          <DialogClose>
            <p className="text-lg font-semibold text-[#274760]">Pay using PayU</p>
            <button
              className="cs_btn cs_style_1"
              onClick={payU}
              disabled={isPending}
            >
              <span>{isPending ? <Loader/> : "Pay Now"}</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPaymentGateway;
