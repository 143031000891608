import React from "react";
import Hero from "../Hero";
import AboutSection from "../Section/AboutSection";
import Banner from "../Section/BannerSection";
import Section from "../Section";
import FeaturesSection from "../Section/FeaturesSection";
import TestimonialSection from "../Section/TestimonialSection";
import BlogSection from "../Section/BlogSection";
import AppointmentSection from "../Section/AppointmentSection";
import FaqSection from "../Section/FaqSection";
import AwardSection from "../Section/AwardSection";
import DepartmentSection from "../Section/DepartmentSection";
import { pageTitle } from "../../helpers/PageTitle";
const featureListData = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Wellness Program",
    subTitle:
      "We understand that seeking medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our patients at ease and every one.",
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Health Care",
    subTitle:
      "We are committed to providing excellent healthcare services to our health-seekers. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.",
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Healthcare Counseling",
    subTitle: `We believe in providing healthcare with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.`,
  },
  {
    iconSrc: "/images/home_1/respect.svg",
    title: "Nutrition Program",
    subTitle:
      "We treat all individuals with respect and dignity, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness.",
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Long-Term Care",
    subTitle:
      "We believe in working collaboratively with our team members and other healthcare professionals to provide comprehensive and effective care to our society.",
  },
];

const faqData = [
  {
    title: "What services does NHWM offer?",
    content:
      "NHWM guides you in preventing chronic disease and enhancing your overall wellness. Our comprehensive services, from health consultations and fitness programs to nutritional counseling and mental health support, provide you with the tools for a healthy life.",
  },
  {
    title: "How do I schedule an appointment with NHWM?",
    content:
      "You can simply call us at our hotline number or book an appointment online. Our team will be happy to assist you in scheduling a convenient time for your health session.",
  },
  {
    title: "Is is possible to consult with a specialist online?",
    content:
      "Yes it is possible to consult with a specialist online. You can book an online consultation with our Health professionals and receive the care you need from the comfort of your home.",
  },
  {
    title: "How health checkup works with chornic disease?",
    content:
      "Our health checkup program is designed to help you manage chronic diseases and improve your overall health. Our team of specialists will work with you to create a personalized treatment plan that meets your unique needs and goals.",
  },
  {
    title: "How the Free Health Session works?",
    content:
      "We are concerned about your health and well-being. Our free health sessions are designed to provide you with the information and resources you need to make informed decisions about your health. Our team of experts will be available to answer any questions you may have and provide you with the support you need to take control of your health.",
  },
];
const blogData = [
  {
    title:
      "Next Free Health Session in Guwahati, Assam in coming September 2024",
    thumbUrl: "images/nhwm/health_event.jpg",
    date: "September 1, 2024",
    btnText: "Learn More",
    href: "/",
    socialShare: true,
  },
  {
    title: "Leran More About Our Health & Wellness Program",
    thumbUrl: "images/nhwm/wellness_program.jpg",
    date: "September 9, 2024",
    btnText: "Learn More",
    href: "/",
    socialShare: true,
  },
  {
    title: "Importance of Regular Health Checkup & Analysis",
    thumbUrl: "images/nhwm/regular_checkup.jpg",
    date: "September 14, 2024",
    btnText: "Learn More",
    href: "/",
    socialShare: true,
  },
];
const awardData = [
  {
    title: "Free Health Sessions",
    subTitle:
      "This award recognizes healthcare organizations that have demonstrated excellence in leadership, strategic planning, customer and employee satisfaction, and operational efficiency.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Health Checkup & Analysis",
    subTitle:
      "This award recognizes healthcare organizations that have used health information technology to improve patient outcomes and reduce costs.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Intensive Long-term care & Lifestyle Disease",
    subTitle:
      "This recognition is given to hospitals that have achieved high ratings for clinical quality and patient safety across multiple specialties and procedures.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Medical Consultation & Treatment",
    subTitle:
      "This recognition is given to hospitals that have met rigorous standards for patient safety and quality of care.",
    iconUrl: "images/icons/award.svg",
  },
];
const departmentData = [
  {
    title: "Diabetics Nutrition & Wellness",
    iconUrl: "/images/home_1/diabetes_icon.svg",
    href: "/departments/department-details",
  },
  {
    title: "Children Nutrition & Wellness",
    iconUrl: "images/home_1/department_icon_2.svg",
    href: "/departments/department-details",
  },
  {
    title: "Woman Nutrition & Wellness",
    iconUrl: "images/home_1/department_icon_3.svg",
    href: "/departments/department-details",
  },
  {
    title: "Cardiac Nutrition & Wellness",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/departments/department-details",
  },
  {
    title: "Immunity Nutrition & Wellness",
    iconUrl: "/images/home_1/immunity_icon.svg",
    href: "/departments/department-details",
  },
  {
    title: "Neuronal Nutrition & Wellness",
    iconUrl: "/images/home_1/department_icon_6.svg",
    href: "/departments/department-details",
  },
];

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title="Your Partner in Healthcare and Wellness"
        subTitle="Our dedicated specialists provide personalized care and innovative solutions to cure disease and guide you on your journey to optimal health and a fulfilling life."
        bgUrl="/images/nhwm/bg_cover.jpg"
        imgUrl="/images/home_1/hero_img.jpg"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: "Hotline",
            subTitle: "+91 361 796 80 83",
            iconUrl: "/images/contact/icon_1.svg",
          },
          {
            title: "Email",
            subTitle: "nhwm.info@gmail.com",
            iconUrl: "/images/contact/icon_2.svg",
          },
          {
            title: "Location",
            subTitle: "Hengrabari, Guwahati, Assam",
            iconUrl: "/images/icons/pin.svg",
          },
        ]}
        btnText="Register Now"
        btnUrl="/register"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Services" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/nhwm/about_us_home.jpg"
          spiningImgUrl="/images/nhwm/logo.png"
          title="About Us"
          subTitle="Healthcare & Wellness Program"
          featureList={[
            {
              featureListTitle:
                "NHWM is a team of experienced Healthcare & Wellness professionals",
              featureListSubTitle:
                "NHWM is a national collaborative MISSION in association with Optim Health, a leading Organization for Healthcare & Wellness  in India. We are educating & helping people to get prevented & reverse from Degenerative Lifestyle Diseases and educating people to maintain the Wellness by Free & Paid Healthcare & Wellness Services like Healthcare & Wellness Awareness & Education Service, Nutritional Healthcare & Wellness Assessment Services, Healthcare & Wellness Coaching & Training, Personalized Healthcare & Wellness Counselling, Workshops’ Registration and Recruiting Membership (Half-yearly, Yearly or Lifetime basis). NHWM is the only organization in PAN  India engaging in Nutritional Assessment & Wellness Awareness Programmes since 2012 for all age groups along with expert guidance towards the prevention & reversal of all Degenerative Diseases like Diabetes, High BP, Heart Disease, Cancer, Arthritis, Obesity etc.",
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Our Experties"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start Award Section */}
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Available Services" data={awardData} />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of Our Customers"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/nhwm/checkup.jpg"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Book your Free health session today that will be conduct in your area by our specialist!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="Free Sessions"
          data={blogData}
        />
      </Section>
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Registration"
          sectionTitleUp="Register Today for a Free Health Consultation"
          imgUrl="/images/nhwm/registration.jpg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        {/* <BrandsSection data={brandData} /> */}
      </Section>
      {/* End Brand Section */}
    </>
  );
}

// using 