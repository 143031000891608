import { useLocation } from "react-router-dom";
import DashboardLayout from "../../../ui/desktop/DashboardLayout";
import Card from "../../../ui/common/Card";
import { Button } from "../../../ui/input/Button";
import { useSelector } from "react-redux";
import { selectCustomer } from "../../../../store/customerSlice";
import {
  getPaymentDetails,
  getPayUpaymentDetails,
  subscribePlan,
} from "../../../../api/customer.api";
import { toast } from "sonner";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../../api/appConst";
import SelectPaymentGateway from "../../../Dialog/SelectPaymentGateway";
import { isPending } from "@reduxjs/toolkit";
import { Hourglass } from "react-loader-spinner";

// razorpay script loading
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Payment = () => {
  const location = useLocation();
  const { plan } = location.state || {};
  const customer = useSelector(selectCustomer);
  const [loading, setLoading] = useState(false);

  const extractAmount = (str) => {
    let match = str.match(/\d+(\.\d+)?/);
    return match ? parseFloat(match[0]) : null;
  };

  const GST = (amount) => {
    const price = extractAmount(amount);
    const gst = price * 0.18;
    return Number(gst.toFixed(2));
  };

  const GATEWAY_FEE = (amount) => {
    const price = extractAmount(amount);
    const fixedFee = 0.5;
    const fee = price * 0.02 + fixedFee;
    return Number(fee.toFixed(2));
  };

  const TOTAL_AMOUNT = (amount) => {
    const price = extractAmount(amount);
    const total = GST(amount) + GATEWAY_FEE(amount) + Number(price);
    return Number(total.toFixed(2));
  };

  // handle razorpay payment method

  const handleRazorpayPaymentMethod = async () => {
    setLoading(true);
    try {
      const response = await subscribePlan(
        customer.id,
        plan.service,
        plan.duration,
        plan.member
      );

      if (response.message.toLowerCase() === "success") {
        const subscriptionId = response.data.subscription[0].id;

        await displayRazorpay(subscriptionId);
      }
    } catch (error) {
      toast.error("Failed to Subscribe!");
      console.error(error, "Failed to Subscribe Plan!");
    } finally {
      setLoading(false);
    }
  };

  async function displayRazorpay(subscriptionId) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razropay failed to load!!");
      return;
    }

    const data = await getPaymentDetails(subscriptionId, customer.id);

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: data.data.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "NHWM",
      description: "Test Transaction",
      image: "/images/nhwm_logo.png",
      order_id: data.data.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: `${BASE_URL}/razorpay-verify/${data.data.order.id}`,
      prefill: {
        name: `${customer.first_name} ${
          customer.middle_name ? customer.middle_name : ""
        } ${customer.last_name}`,
        email: customer.email,
        contact: customer.phohe_no,
      },
      notes: {
        address: "NHWM Corporate Office",
        receipt: data.data.order.receipt,
      },
      theme: {
        color: "#274760",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  // handle payU payment method
  const [processing, setProcessing] = useState(false);

  const handlePayuPaymentMethod = async () => {
    setProcessing(true);
    try {
      const response = await subscribePlan(
        customer.id,
        plan.service,
        plan.duration,
        plan.member
      );

      if (
        response.message.toLowerCase() === "success" &&
        response.data?.subscription?.length
      ) {
        const subscriptionId = response.data.subscription[0].id;
        await initiatePayUPayment(subscriptionId);
      } else {
        throw new Error("Invalid subscription response");
      }
    } catch (error) {
      toast.error("Failed to Subscribe!");
      console.error("Subscription Error:", error);
    } finally {
      setProcessing(false);
    }
  };

  const initiatePayUPayment = async (subscriptionId) => {
    setProcessing(true);
    try {
      const response = await getPayUpaymentDetails(subscriptionId, customer.id);

      if (response.message.toLowerCase() === "success" && response.data?.data) {
        console.log(response.data.data);
        submitPayUForm(response.data.data);
      } else {
        throw new Error("Invalid payment response format");
      }
    } catch (error) {
      toast.error("Failed to initiate payment!");
      console.error("Payment Initiation Error:", error);
    } finally{
      setProcessing(false);
    }
  };

  const submitPayUForm = (formHtml) => {
    setProcessing(true);
    const div = document.createElement("div");
    div.innerHTML = formHtml;
    const form = div.querySelector("form");
    if (form) {
      document.body.appendChild(div);
      form.submit();
      setProcessing(false);
    } else {
      throw new Error("Payment form not found in response");
    }
  };

  return (
    <DashboardLayout>
      <div
        className="min-h-[90dvh] lg:min-h-[100vh] w-full bg-cover bg-center"
        style={{
          backgroundImage: "url('/images/home_2/appointment_bg.svg')",
        }}
      >
        {processing ? (
          <div className="w-full h-[70vh] flex justify-center items-center">
            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["#306cce", "#72a1ed"]}
            />
          </div>
        ) : (
          <div className="p-6">
            <h2>Selected Plan</h2>
            <div className="flex justify-center items-center">
              <div className="lg:px-4 w-fit">
                <h3>
                  {plan.duration === 3 ? (
                    <span>3 Months </span>
                  ) : (
                    <span>12 Months </span>
                  )}
                  {plan.service === "type1" ? (
                    <span>Paid Health Check-Up</span>
                  ) : (
                    <span>Paid Health Check-Up with Wellness Assurance</span>
                  )}
                </h3>
                <Card className="min-w-[342px]">
                  <ul className="list-none">
                    <li className="capitalize text-lg">-{plan.perks.perk1}</li>
                    <li className="capitalize text-lg">-{plan.perks.perk2}</li>
                    <li className="capitalize text-lg">-{plan.perks.perk3}</li>
                    <li className="capitalize text-lg">-{plan.perks.perk4}</li>
                    {plan.service === "type2" && (
                      <li className="capitalize text-lg">
                        -{plan.perks.perk5}
                      </li>
                    )}
                  </ul>
                  <h4 className="mb-0 text-[#274760] text-2xl font-semibold">
                    {plan.price}
                  </h4>
                  <div className="border-t border-gray-400 my-2"></div>
                  <p className="mb-0">
                    2% Payment Gateway Fee : Rs. {GATEWAY_FEE(plan.price)}/-
                  </p>
                  <p className="mb-0">18% GST : Rs. {GST(plan.price)}/-</p>
                  <div className="border-t border-gray-400 my-2"></div>
                  <h4 className=" text-[#274760]">
                    Total Amount Payable : Rs. {TOTAL_AMOUNT(plan.price)}/-
                  </h4>
                  <div className="w-full flex justify-center">
                    <SelectPaymentGateway
                      razorPay={handleRazorpayPaymentMethod}
                      payU={handlePayuPaymentMethod}
                      isPending={loading}
                    >
                      <Button disabled={isPending}>Make Payment</Button>
                    </SelectPaymentGateway>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Payment;
