import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  customer: null,
  subscriptionDetail: {},
  paymentDetails: {},
}

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setSubscriptionDetail: (state, action) => {
      state.subscriptionDetail = action.payload;
    },
    setPaymentDetails: (state, action) => {
      state.paymentDetails = action.payload;
    },
  }
});

export const selectCustomer = (state) => state.customer.customer;
export const {setCustomer, setSubscriptionDetail, paymentDetails} = customerSlice.actions;
export default customerSlice.reducer;