import axios from "axios";
import { BASE_URL } from "./appConst";
import { axiosAuth } from "./auth.api";

export const registerCustomer = async (customerDetails) => {

    const payload = {
      first_name : customerDetails.first_name,
      middle_name : customerDetails.middle_name || "",
      last_name : customerDetails.last_name,
      date_of_birth: customerDetails.date_of_birth.toString(),
      gender: customerDetails.gender,
      phone_no: customerDetails.phone_no,
      whatsapp_number: customerDetails.whatsapp_number || "",
      email: customerDetails.email,
      address: customerDetails.address || "",
      state: customerDetails.state,
      city: customerDetails.city,
      pin: customerDetails.pin,
    }

    const response = await axios.post(`${BASE_URL}/user-registration`, payload);

    return response.data;
};

export const getCustomerInfo = async () => {
  try {
    const api = axiosAuth();

    const response = await api.get(`${BASE_URL}/customer-info`);

    return response.data;

  } catch (error) {
    console.error(error, "Couldn't get customer info");
    window.location.replace("/signup");
  }
}

export const getFreeSessionSlots = async ({state}) => {
  try {
    const api = axiosAuth();

    const response = await api.get(`${BASE_URL}/get-free-session-slot?state=${state}`);

    return response.data;

  } catch (error) {
    console.error(error, "Couldn't get session slots");
  }
}

export const bookFreeSession = async (sessionId, customerId) => {
  try {
    const api = axiosAuth();

    const response = await api.post(`${BASE_URL}/book-free-session`, {slot: sessionId, customer_id: customerId});

    return response.data;

  } catch (error) {
    console.error(error, "Couldn't book session");
  }
}

export const getBookedSession = async (customerId) => {
  try {
    const api = axiosAuth();

    const response = await api.get(
      `${BASE_URL}/customer/sessions/${customerId}?page=1&pagesize=2`
    );

    return response.data;

  } catch (error) {
    console.error(error, "Something went wrong while getting the booked session ")
  }
}

// subscribe to a plan

export const subscribePlan = async (customer_id, service, duration, member) => {
  const api = axiosAuth();

  const response = await api.post(`${BASE_URL}/subscribe`, {
    customer_id: customer_id, 
    service: service, 
    duration: duration, 
    member: member,
  });

  return response.data;
};

// get subscription details for the customer

export const getSubscriptions = async (customerId) => {
  const api = axiosAuth();

  const response = await api.get(`${BASE_URL}/get-subscription/${customerId}`);

  return response.data;
};

// pay using razorpay

export const getPaymentDetails = async (subscriptionId, customerId) => {
  const api = axiosAuth();

  const response = await api.post(`${BASE_URL}/razorpay`, {
    subscription_id: subscriptionId,
    customer_id: customerId,
  });

  return response.data;
};

// get razorpay payment details

export const getRazorpayPaymentDetails = async (paymentId) => {
  const api = axiosAuth();

  const response = await api.get(
    `${BASE_URL}/payment-details-razorpay/${paymentId}`
  );

  return response.data;
};

// pay using payU

export const getPayUpaymentDetails = async (subscriptionId, customerId) => {
  const api = axiosAuth();

  const response = await api.post(`${BASE_URL}/payu-payment`, {
    subscription_id: subscriptionId,
    customer_id: customerId,
  });

  return response.data;
}
