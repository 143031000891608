import React from "react";
import IconBoxStyle11 from "../../IconBox/IconBoxStyle11";
import Spacing from "../../Spacing";

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
          <div className="cs_iconbox cs_style_11 cs_radius_25">
            <div className="cs_iconbox_icon">
              <img src="/images/contact/icon_1.svg" alt="Icon" />
            </div>
            <div className="cs_iconbox_right">
              <h3 className="cs_iconbox_title cs_fs_24 mb-0">Phone</h3>
              <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                +91 3617968083
              </p>
              <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                10am-6pm, Mon-Fri, except holidays
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="nhwm.oh@gmail.com nhwm.info@gmail.com"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Location"
            subTitle="Dr. Zakir Hussain Road, Hengrabari, Guwahati, Assam – 781036, India"
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>
      <Spacing md="35" />
      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3581.4444896330488!2d91.80171077619283!3d26.149649777110326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s16%2C%20R.K.%20Kakati%20Path%2C%20Dr.%20Zakir%20Hussain%20Road%2C%20Near%20Nabajyoti%20Club%2C%20Hengrabari%2C%20Guwahati%2C%20Assam%20%E2%80%93%20781036!5e0!3m2!1sen!2sin!4v1738394302997!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {/* End Google Map */}
    </div>
  );
}
