import React from "react";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
import Section from "../Section";
import FeaturesSectionStyle2 from "../Section/FeaturesSection/FeaturesSectionStyle2";
import TeamSection from "../Section/TeamSection";
import GallerySection from "../Section/GallerySection";
import AwardSectionStyle2 from "../Section/AwardSection/AwardSectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";
import SectionHeading from "../SectionHeading";

const featureListData = [
  {
    title: "Experienced Healthcare Professionals",
    subTitle:
      "Our team includes experienced healthcare professionals who are dedicated to providing the best possible Healthcare services to the society.",
    iconUrl: "images/icons/professional.svg",
  },
  {
    title: "Comprehensive Services",
    subTitle:
      "We offer a wide range of healthcare services, from preventive care to specialized reverting plan for complex conditions.",
    iconUrl: "images/icons/comprehensive.svg",
  },
  {
    title: "Beneficiary-centered <br /> Approach",
    subTitle:
      "We believe in caring each health seeker as an individual, and we take the time to understand your unique health needs and concerns.",
    iconUrl: "images/icons/patient.svg",
  },
  {
    title: "State-of-the-art Facilities",
    subTitle:
      "Our healthcare professionals are equipped with the latest technology and equipment to provide health-seekers with the most advanced care & counselling possible.",
    iconUrl: "images/icons/facilities.svg",
  },
];

const teamData = [
  {
    imgUrl: "/images/nhwm/1_Dr_Amal_Kumar_Sharma.png",
    name: "Dr. Amal Kr Sharma",
    designation: "Advisor, NHWM",
    description:
      "Dr. Amal Kr. Sharma had completed his MBBS from Guwahati Medical College in 1969, worked as Registrar in Radiology at Silchar Medical College & Hospital from 1970 to 1972. Returned to Guwahati Medical College for pursuing Post Graduation in Surgery (Orthopaedics and General Surgery). Dr. Amal Kr. Sharma had completed P G in 1977. Dr. Amal Kr. Sharma went to Syria to work as Surgeon Specialist under the Govt. of Syrian Arab Republic on deputation by Govt of India from 1978 to 1984, also went to various European Countries like Italy, Germany, Switzerland & Turkey & visited many Hospitals there to acquire knowledge of modern Surgical procedures & equipments used in those developed countries. At present Dr. Amal Kr. Sharma devoting  his time in treating patients with chronic diseases by giving much time to know their life style & find out the root cause of their diseases & treating the patients with Applied and Clinical Nutrition along with Modern Medical Practice. Dr. Amal Kr. Sharma providing a holistic approach also towards preventing further illnesses of Chronic Degenerative Diseases (Holistic health) and maintaining a healthy lifestyle. Dr. Amal Kr. Sharma is socially involved person and most demandable speaker for Holistic Healthcare  Workshops.",
    social: [
      {
        icon: "fa6-brands:facebook-f",
        href: "https://www.facebook.com/dramalkumar.sharma",
      },
    ],
  },
  {
    imgUrl: "/images/nhwm/2_Dr._Arundhati_Deka.png",
    name: "Dr. Arundhati Deka",
    designation: "Advisor, NHWM",
    description:
      "Dr. Arundhati Deka is a Master Graduate in Food & Nutrition and Dietetics from Sri Avinashilingam Women University, Coimbatore & PhD in Community Nutrition in Faculty of Medicine, Gauhati University. She got 3 Month training in “Nutrition Programme Planning and Management” in University of Philippines, Los Banos & 15 days training in John Hopkins University , Baltimore , Maryland , USA on “Leadership Management Training”. About 29 years she is involving as State Programme officer for 3 National Programmes under Health & FW Dept. namely “National Iodine Deficiency Disorders Control Programme”; “National Programme for Prevention and Control of Fluorosis” & “National Tobacco Control Programme”. Dr. Arundhati Deka was State Secretary for Breast Feeding Promotion Network of India for 3 years. Dr. Arundhati Deka is Life member for :- Indian Public Health Association (IPHA) & Nutrition Society of India and acted as Resource person in — NIPCCD, SIH & FW, Many Training Institutes and Govt. Departments. ACT as Expert in APSC interviews. Dr. Arundhati Deka written jointly many Course Book on Nutrition and Human Development for Gauhati, Dibrugarh and Manipur University which are using as course book in Under Graduate Level.",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
    ],
  },
  {
    imgUrl: "/images/nhwm/3_Adv_Robin_Boruah.png",
    name: "Adv. Robin Boruah",
    designation: "Legal Advisor",
    description:
      "Adv. Robin Boruah obtained his LLB degree from University Law College, Gauhati University in 2003. In the same year, he enrolled in the Bar Council of Assam, Nagaland, Meghalaya, Manipur, Tripura, and Arunachal Pradesh, and subsequently joined the legal profession. He has served as standing counsel for the Education Department to the Government of Assam in the Hon’ble Gauhati High Court and for the Finance (Taxation) Department to the Government of Assam in the Hon’ble Assam Board of Revenue. Adv. Boruah has represented clients in various cases, including writ petitions before the High Court, and cases in the Civil, Criminal, and Motor Accident Claims Tribunals, as well as the Central Administrative Tribunal, Guwahati Bench, and the Railway Tribunal, Guwahati.",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
    ],
  },
  {
    imgUrl: "/images/nhwm/4_Dr._Monideep_Boruah.png",
    name: "Dr. Monideep Boruah",
    designation: "MD, NHWM",
    description:
      "Dr. Monideep Boruah is an Applied & Clinical Nutritionist and Reviewer Scientist of  European Journal of Nutrition since 2014. He is a Public Speaker, Global Wellness Specialist and Healthcare Coach. Dr. Monideep Boruah has been helping many people to get prevented & reversing to normal condition from Deathly Lifestyle Diseases across the Globe by altering their food habits and lifestyle habits.",
    social: [
      {
        icon: "fa6-brands:facebook-f",
        href: "https://www.facebook.com/drmonideep.boruah",
      },
      {
        icon: "fa6-brands:linkedin-in",
        href: "https://www.linkedin.com/in/dr-monideep-boruah-608104163/",
      },
    ],
  },
];

const galleryData = [
  { imgUrl: "/images/social/1.jpg" },
  { imgUrl: "/images/social/2.jpg" },
  { imgUrl: "/images/social/3.jpg" },
  { imgUrl: "/images/social/4.jpg" },
  { imgUrl: "/images/social/5.jpg" },
  { imgUrl: "/images/social/6.jpg" },
  { imgUrl: "/images/social/7.jpg" },
  { imgUrl: "/images/social/8.jpg" },
];
const awardData = [
  {
    iconUrl: "/images/icons/award.svg",
    title: "Malcolm Baldrige National Quality Award",
  },
  { iconUrl: "/images/icons/award.svg", title: "HIMSS Davies Award" },
  {
    iconUrl: "/images/icons/award.svg",
    title: "Healthgrades National’s Best Hospital",
  },
  {
    iconUrl: "/images/icons/award.svg",
    title: "Joint Commission Gold Seal of Approval",
  },
];

export default function About() {
  pageTitle("About");
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/nhwm/about_us_home.jpg"
        title="Welcome to <br />National Healthcare & Wellness Mission"
        subTitle="Your Partner in Healthcare and Wellness"
      />
      <Section topMd={140} topLg={120} topXl={80}>
        <SectionHeading title="About NHWM" center />
        <div className="md:w-[80%] mx-auto">
          <p className="mt-4 text-xl text-justify text-[#274760]">
            NATIONAL HEALTHCARE AND WELLNESS MISSION is a collaboration of
            OPTIM HEALTH AND WELLNESS (OPC) PRIVATE LIMITED, a
            leading Organization for Healthcare & Wellness in India. We are
            educating & helping people to get prevented & reverse from
            Degenerative Lifestyle Diseases and educating people to maintain the
            Wellness by Free & Paid Healthcare & Wellness Services like
            Healthcare & Wellness Awareness & Education Service, Nutritional
            Healthcare & Wellness Assessment Services, Healthcare & Wellness
            Coaching & Training, Personalized Healthcare & Wellness Counselling,
            Workshops’ Registration and Recruiting Membership (Half-yearly,
            Yearly or Lifetime basis). NHWM is the only organization in PAN 
            India engaging in Nutritional Assessment & Wellness Awareness
            Programmes since 2012 for all age groups along with expert guidance
            towards the prevention & reversal of all Degenerative Diseases like
            Diabetes, High BP, Heart Disease, Cancer, Arthritis, Obesity etc.
          </p>
        </div>
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="/images/about/why_choose_us_image.jpg"
          data={featureListData}
        />
      </Section>
      <Section topMd={140} topLg={125} topXl={105}>
        <TeamSection
          sectionTitle="Director & Advisors"
          sectionTitleUp="MEET OUR"
          data={teamData}
        />
      </Section>
      <Section topMd={200} topLg={150} topXl={110}>
        <div className="w-[80%] mx-auto">
          <SectionHeading title="Our Mission" center />
          <p className="mt-4 text-4xl capitalize text-center text-[#274760]">
            Helping 10 Million people by 31st Dec' 2030 to get prevented or
            reverse from Diabetes & other Lifestyle Diseases.
          </p>
        </div>
        <div className="w-[80%] mx-auto mt-12">
          <SectionHeading title="Our Vission" center />
          <p className="mt-4 text-4xl capitalize text-center text-[#274760]">
            Becoming a Pioneer Organization in India by Educating & Helping
            People How to Live a Healthy, Wealthy & Happy Life.
          </p>
        </div>
      </Section>
      <Section topMd={140} topLg={120} topXl={80}>
        <SectionHeading title="Message from Mission Director" center />
        <div className="md:w-[80%] mx-auto">
          <p className="mt-4 text-xl text-justify text-[#274760]">
            We are honoured to inform you that, as part of our moral and social
            responsibility, we are committed to helping those around us stay
            healthy through our Educational Health Awareness Programme. This
            program includes proper health assessments (health check-ups) and
            personalized counselling. It is purely an educational initiative
            focused on nutritional health check-ups, where we educate people on
            how to prevent or reverse lifestyle diseases. <br /> <br />
            We have now launched a collaborative PAN India mission named the
            “National Healthcare & Wellness Mission”. As part of this mission,
            we have conducted a Basic Recruitment Test (BRT 2023-24) to recruit
            the right individuals to join us, but only a few have qualifies
            which is very less than our requirements. So, we are going to
            conduct another BRT in June 2025 and advertisement will be releases
            in March 2025. Our goal of this mission is to – “help & educate 10
            million people by December 31, 2030, to prevent or reverse lifestyle
            diseases”.
            <br /> <br />
            We invite all citizens of India to join our mission and provide
            valuable advice to help us move forward. Together, we can educate
            and support people to avoid potential health crises in the future.
            This is a moral and social responsibility for everyone in our
            society. We assure you that we will maintain positive relationships
            with all members of our Indian society. No one, inside or outside
            the mission, will be allowed to engage in any monetary scams or
            anti-social activities in the name of NHWM.
          </p>
          <p className="text-xl text-start text-[#274760] mt-2">
            Sincerely, <br />
            Dr. Monideep Boruah <br />
            Mission Director, NHWM
          </p>
        </div>
      </Section>
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Our Social Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/nhwm/about_bg.jpg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />Healthcare Professionals today!"
          center
        />
      </Section>
    </>
  );
}

// using in about page 