import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import DashboardView from "./components/user/dashboard";
import PaidSession from "./components/user/sessions/paid";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import Doctors from "./components/Pages/Doctors";
import DoctorDetails from "./components/Pages/DoctorDetails";
import Blog from "./components/Pages/Blog";
import BlogDetails from "./components/Pages/BlogDetails";
import Appointments from "./components/Pages/Appointments";
import Departments from "./components/Pages/Departments";
import DepartmentDetails from "./components/Pages/DepartmentDetails";
import PricingPlan from "./components/Pages/PricingPlan";
import Gallery from "./components/Pages/Gallery";
import Timetable from "./components/Pages/Timetable";
import Contact from "./components/Pages/Contact";
import ErrorPage from "./components/Pages/ErrorPage";
import HealthReport from "./components/user/healthReport";
import Counselling from "./components/user/counselling";
import Subscriptions from "./components/user/subscriptions";
import AccountSettings from "./components/user/myAccount";
import Notification from "./components/user/notification";
import FreeSession from "./components/user/sessions/free";
import AuthenticationView from "./components/auth";
import ResetPassword from "./components/auth/resetpassword";
import ForgotPassword from "./components/auth/forgotpassword";
import Payment from "./components/user/sessions/paid/make-payment";
import PaymentSuccess from "./components/user/sessions/paid/payment-success";
import PaymentFailed from "./components/user/sessions/paid/payment-failded";
import PrivacyPolicies from "./components/Pages/privacy-policies";
import Terms from "./components/Pages/Terms";

const authRoutesConfig = [
  { path: "/signup", element: <AuthenticationView /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
];

const authRoutes = authRoutesConfig.map((route) => route.path);

const publicRoutesConfig = [
  { path: "about", element: <About /> },
  { path: "doctors", element: <Doctors /> },
  { path: "doctors/:doctorId", element: <DoctorDetails /> },
  { path: "blog", element: <Blog /> },
  { path: "blog/:blogId", element: <BlogDetails /> },
  { path: "register", element: <Appointments /> },
  { path: "departments", element: <Departments /> },
  { path: "departments/:departmentId", element: <DepartmentDetails /> },
  { path: "pricing-plan", element: <PricingPlan /> },
  { path: "gallery", element: <Gallery /> },
  { path: "timetable", element: <Timetable /> },
  { path: "contact", element: <Contact /> },
  { path: "privacy-policies", element: <PrivacyPolicies /> },
  { path: "terms", element: <Terms /> },
  { path: "*", element: <ErrorPage /> },
];

const publicRoutes = publicRoutesConfig.map((route) => route.path);

const protectedRoutesConfig = [
  { path: "/dashboard", element: <DashboardView /> },
  { path: "/healthreport", element: <HealthReport /> },
  { path: "/counselling", element: <Counselling /> },
  { path: "/subscriptions", element: <Subscriptions /> },
  { path: "/accountsettings", element: <AccountSettings /> },
  { path: "/notification", element: <Notification /> },
  { path: "/free-session", element: <FreeSession /> },
  { path: "/paid-session", element: <PaidSession /> },
  { path: "/make-payment", element: <Payment /> },
  { path: "/payment-success", element: <PaymentSuccess /> },
  { path: "/payment-failed", element: <PaymentFailed /> },
];

const protectedRoutes = protectedRoutesConfig.map((route) => route.path);

const PublicRoutes = () => {
  return (
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      {publicRoutesConfig.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
    </Route>
  );
};

const ProtectedRoutes = () => {
  return (
    <>
      {protectedRoutesConfig.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
    </>
  );
};

const AuthRoutes = () => {
  return (
    <>
      {authRoutesConfig.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
    </>
  );
};

export const RouteManager = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const accessToken = localStorage.getItem("accessToken");
  const isAuthenticated = !!accessToken;
  
  return (
    <Routes>
      {isAuthenticated ? 
      (
        <>
          {ProtectedRoutes()}
          {PublicRoutes()}
          {AuthRoutes()}
        </>
        
      ) : (
        <>
          {PublicRoutes()}
          {AuthRoutes()}
        </>
      )}
    </Routes>
  );
};

export { publicRoutes, protectedRoutes, authRoutes };
