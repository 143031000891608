import React from "react";
import ContactForm from "../ContactForm";
import Section from "../Section";
import ContactInfoSection from "../Section/ContactInfoSection";
import { pageTitle } from "../../helpers/PageTitle";

export default function Contact() {
  pageTitle("Contact");
  return (
    <>
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        style={{ backgroundImage: "url(/images/contact/banner_bg.svg)" }}
      >
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Contact Us</h2>
            <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
              Write to Us with your Queries we will help you with your needs for
              your health and wellness.
            </p>
          </div>
        </div>
      </section>
      <div className="container cs_mt_minus_110">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ContactForm />
          </div>
        </div>
      </div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Find Us Here" />
      </Section>
    </>
  );
}

// using in contact page